<template lang="pug">
  .cm-chips
    template(v-if="!hideAdditionalChips")
      v-chip(
        v-if="$route.query.city"
        :color='isMultipleMode ? VIOLET_COLOR : "primary"'
        :class="{'text-white': isMultipleMode}"
      ) {{ currentLocation }}
      v-chip(
        v-for="course in sortedActiveCourses"
        :color='isMultipleMode ? VIOLET_COLOR : "primary"'
        :close="isMultipleMode"
        :class="{'text-white': isMultipleMode}"
        :key="course.ID"
        close-icon="mdi-delete"
        data-test="cm-date"
        @click:close="$emit('delete', course)"
      )
        .overflow-text {{ getCourseDate(course) | DOWDutchDDD }} {{ getCourseDate(course) }} {{ getCourseName(course) }}
    portal-target(:name="portalChipName" slim)
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { VIOLET_COLOR } from '../core/cm-const'
import { sortByDate } from '@/util'

export default {
  props: {
    activeCourses: Array,
    hideAdditionalChips: Boolean,
    portalChipName: String
  },
  data: () => ({
    VIOLET_COLOR
  }),
  methods: {
    getCourseDate(course) {
      return course.date ? course.date : ""
    },
    getCourseName(course) {
      return course.name ? course.name : ""
    }
  },
  computed: {
    sortedActiveCourses() {
      if (!this.activeCourses) return []
      return sortByDate(this.activeCourses)
    },
    ...mapState('cm', {
      isMultipleMode: state => state.isMultipleMode,
    }),
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items'
    }),
    currentLocation() {
      if (!this.$route.query.city || !this.crmCourseLocation) return ''
      let city = this.crmCourseLocation.find(item => item.ID === +this.$route.query.city)
      if (city) return city.name
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.cm-chips {
  margin-top: 10px;

  .v-chip {
    margin-right: 8px;
    margin-bottom: 16px;
    max-width: 250px;
    font-weight: 500;
  }

  .text-white {
    color: white;
  }
}
</style>
